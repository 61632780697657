var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailInfo",
              attrs: { title: "유해위험기계기구 기본정보", collapsed: true },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _vm.editable && _vm.equipInfo.plantNm
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "사업장",
                            value: _vm.equipInfo.plantNm,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.itemNo
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "기계분류",
                            value: _vm.equipInfo.itemNo,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.machineryName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "기계명",
                            value: _vm.equipInfo.machineryName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.machineryClassificationName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "item No",
                            value: _vm.equipInfo.machineryClassificationName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.departmentDeptName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "담당부서",
                            value: _vm.equipInfo.departmentDeptName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.departmentUserName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "담당자",
                            value: _vm.equipInfo.departmentUserName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.emplacementMonth
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "설치년월",
                            value: _vm.equipInfo.emplacementMonth,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.gradeName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "검사등급",
                            value: _vm.equipInfo.gradeName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.inspectionSubjectFlagName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "검사대상",
                            value: _vm.equipInfo.inspectionSubjectFlagName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.inspectionKindName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "검사종류",
                            value: _vm.equipInfo.inspectionKindName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.inspectionCycleName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "검사주기",
                            value: _vm.equipInfo.inspectionCycleName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.equipInfo.resultName
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                      },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "검사결과",
                            value: _vm.equipInfo.resultName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "tableImpr",
              attrs: {
                title: "개선 목록",
                columns: _vm.gridImpr.columns,
                data: _vm.tabParam.equipmentImproveModels,
                merge: _vm.gridImpr.merge,
                selection: "multiple",
                rowKey: "equipImproveId",
                usePaging: false,
                filtering: false,
                columnSetting: false,
                editable: _vm.editable,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      props.row["sopImprovementIds"] &&
                      props.row["ibmStepNames"]
                        ? _vm._l(
                            _vm.chipDatas(
                              props.row["sopImprovementIds"],
                              props.row["ibmStepNames"],
                              props.row["ibmClassCds"]
                            ),
                            function (item, index) {
                              return _c(
                                "q-chip",
                                {
                                  key: index,
                                  staticStyle: {
                                    "margin-bottom": "4px !important",
                                  },
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    color:
                                      item.ibmClassCd === "IC00000001"
                                        ? "blue-6"
                                        : "orange",
                                    clickable: true,
                                    "text-color": "white",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkClick(item, props.row)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.title) + " ")]
                              )
                            }
                          )
                        : _vm._e(),
                      props.row.editFlag !== "C"
                        ? _c(
                            "div",
                            [
                              _c(
                                "q-btn-group",
                                {
                                  staticClass: "ColumInnerBtnGroup",
                                  attrs: { outline: "" },
                                },
                                [
                                  _vm.editable
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticClass: "ColumInnerBtn",
                                          attrs: {
                                            icon: "add",
                                            color: "blue-6",
                                            "text-color": "white",
                                            align: "center",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.innerBtnClicked(
                                                col,
                                                props,
                                                "1"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("q-tooltip", [
                                            _vm._v(" 개선요청등록 "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.editable
                                    ? _c(
                                        "q-btn",
                                        {
                                          staticClass: "ColumInnerBtn",
                                          attrs: {
                                            icon: "add",
                                            color: "orange",
                                            "text-color": "white",
                                            align: "center",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.innerBtnClicked(
                                                col,
                                                props,
                                                "2"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("q-tooltip", [
                                            _vm._v(" 즉시조치등록 "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              label: "추가",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addItem },
                          })
                        : _vm._e(),
                      _c("c-btn", {
                        attrs: {
                          url: _vm.insertUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.tabParam.equipmentImproveModels,
                          mappingType: "POST",
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveImprove,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                      _vm.editable &&
                      _vm.tabParam.equipmentImproveModels.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "삭제",
                              showLoading: false,
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeItem },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }